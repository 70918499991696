import React from "react"
import styled from "styled-components"

import { colors, buttonOne } from "../../Utilities"

const ErrorDisplayStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.colorPrimary};
  z-index: 999999999;

  .warning-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .warning-content {
    align-self: center;
    max-width: 50rem;
    padding: 3rem;
    background: #fff;
    border-radius: 0.5rem;
    text-align: center;

    button {
      ${buttonOne};
    }
  }
`

const fixTheFormErrors = stateSetter => {
  stateSetter(prevState => {
    return {
      ...prevState,
      errorWarnDisplay: false,
      submitting: false,
    }
  })
}

const ErrorDisplay = ({ stateSetter }) => {
  return (
    <ErrorDisplayStyled>
      <div className="warning-wrapper">
        <div className="warning-content">
          <p>Error!</p>
          <button
            onClick={() => {
              fixTheFormErrors(stateSetter)
            }}
          >
            Close
          </button>
        </div>
      </div>
    </ErrorDisplayStyled>
  )
}

export default ErrorDisplay
