import React from "react"
import styled from "styled-components"

import FlyPicker from "./FlyPicker"
import LinePicker from "./LinePicker"
import {
  standardWrapper,
  headlineTwo,
  headlineThree,
  colors,
  bodyCopy,
} from "../Utilities"

const BasicContentSection = styled.section`
  position: relative;
  padding-top: 15rem;
  padding-bottom: 5rem;

  .bc-wrapper {
    ${standardWrapper};
  }

  .bc-title {
    position: relative;
    text-align: center;

    h2 {
      span {
        display: block;
        text-transform: uppercase;
      }

      span:first-of-type {
        ${headlineThree};
        color: ${colors.colorSecondary};
      }

      span:last-of-type {
        ${headlineTwo};
        color: ${colors.colorPrimary};
      }
    }
  }

  .bc-content {
    p {
      ${bodyCopy};
    }
  }
`

const BasicContent = ({ data, location }) => {
  let fishLineNum = "six"
  if (location === "adventures") {
    fishLineNum = "six"
  } else if (location === "contact") {
    fishLineNum = "five"
  }

  return (
    <BasicContentSection>
      <div className="bc-wrapper">
        <div className="bc-title">
          <h2>
            <span>{data.titleTop}</span>
            <span>{data.titleBot}</span>
          </h2>
          <FlyPicker info={data.flyPicker} />
        </div>
        <div
          className="bc-content"
          dangerouslySetInnerHTML={{ __html: data.mainContent }}
        />
      </div>
      <LinePicker info={fishLineNum} />
    </BasicContentSection>
  )
}

export default BasicContent
