import axios from "axios"

export const submitTheForm = (e, formID, formData, settingState) => {
  e.preventDefault()
  const bodyFormData = new FormData()
  formData.forEach(field => {
    bodyFormData.append(Object.keys(field)[0], Object.values(field)[0])
  })

  submitToWebServer(formID, bodyFormData, settingState)
}

export const submitToWebServer = async (formID, data, settingState) => {
  const FORM_POST_URL = `https://driftoutwest.swbdatabases.ca/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback`
  const config = { headers: { "Content-Type": "multipart/form-data" } }
  const serverResponse = await axios.post(FORM_POST_URL, data, config)

  if (serverResponse.data.status === "mail_sent") {
    settingState(prevState => {
      return {
        ...prevState,
        submitting: false,
        success: true,
      }
    })
  } else {
    settingState(prevState => {
      return {
        ...prevState,
        submitting: false,
        errorWarnDisplay: true,
        errors: serverResponse.data.invalidFields,
      }
    })
  }
}
