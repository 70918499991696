import React from "react"
import styled from "styled-components"
import BgImg from "gatsby-background-image"
import { graphql, useStaticQuery, Link } from "gatsby"
import {
  TimelineMax,
  TweenMax,
  RoughEase,
  CSSPlugin,
  AttrPlugin,
} from "gsap/all"
import ScrollMagic from "scrollmagic"
import animationGsap from "animation.gsap"
import debugAddIndicators from "debug.addIndicators"

import { colors, headlineOne, bodyCopy } from "../Utilities"
import FlyIconOne from "../elements/FlyIconOne"
import FlyIconTwo from "../elements/FlyIconTwo"
import FlyIconThree from "../elements/FlyIconThree"

const plugins = [CSSPlugin, AttrPlugin, animationGsap]

const getData = graphql`
  query colAdventures {
    adventures: allWordpressWpAdventure {
      edges {
        node {
          slug
          wordpress_id
          acf {
            _dow_title
            _dow_fly_icon
            _dow_excerpt
            _dow_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const AdventureRowSection = styled.section`
  .ar-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    @media (min-width: 768px) {
      flex-wrap: wrap;
    }
  }
`

const AdventureCard = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 33.33vw;
  min-height: 40rem;
  text-align: center;

  @media (min-width: 768px) {
    flex-grow: 1;
    height: 33.33vw;
    min-height: 50rem;
  }

  @media (min-width: 1025px) {
    height: 33.33vw;
    min-height: 65rem;
  }

  a {
    display: flex;
    position: relative;
    width: 100%;
    z-index: 10;
  }

  .adventure-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
  }

  .adventure-title {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 2rem;
    transform: translate(-50%, -50%);
    z-index: 99999;

    @media (min-width: 768px) {
      width: 55rem;
    }

    .adventure-icon {
      width: 100%;
      max-width: 12.5rem;
      margin: 2rem auto;
      padding: 1rem;
    }

    h2 {
      ${headlineOne};
      margin: 0;
      transition: all 0.3s ease;
      color: ${colors.white};
      text-transform: uppercase;
    }

    .adventure-excerpt {
      p {
        ${bodyCopy};
        transition: all 0.3s ease;
        color: ${colors.white};
      }
    }
  }

  a {
    &:hover {
      h2 {
        color: ${colors.colorTertiary};
      }
      ${"" /* .adventure-excerpt {
        p {
          color: ${colors.colorTertiary};
        }
      } */}
    }
  }

  .adventure-image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.39;
    z-index: 1;
  }
`

const animationFuction = target => {
  const iconTl = new TimelineMax({ repeat: -1, yoyo: true, repeatDelay: 1 })
  const icon = target.parentElement.querySelector(".adventure-icon")

  iconTl.fromTo(
    icon,
    1,
    { x: -1.5 },
    {
      x: 1.5,
      ease: RoughEase.ease.config({
        strength: 8,
        points: 25,
        randomize: false,
      }),
    }
  )
}

const stopAnimation = target => {
  const icon = target.parentElement.querySelector(".adventure-icon")
  TweenMax.killTweensOf(icon)
  TweenMax.set(icon, { x: 0 })
}

const AdventureRow = () => {
  const data = useStaticQuery(getData)
  const {
    adventures: { edges: adventures },
  } = data

  return (
    <AdventureRowSection>
      <div className="ar-wrapper">
        {adventures.map(adventure => {
          let flyIcon = false

          if (adventure.node.acf._dow_fly_icon === "one") {
            flyIcon = <FlyIconOne />
          } else if (adventure.node.acf._dow_fly_icon === "two") {
            flyIcon = <FlyIconTwo />
          } else if (adventure.node.acf._dow_fly_icon === "three") {
            flyIcon = <FlyIconThree />
          }

          return (
            <AdventureCard key={adventure.node.wordpress_id}>
              <Link
                onMouseEnter={e => {
                  animationFuction(e.target)
                }}
                onMouseLeave={e => {
                  stopAnimation(e.target)
                }}
                to={`/adventures/${adventure.node.slug}`}
              >
                <BgImg
                  className="adventure-image"
                  Tag="div"
                  fluid={
                    adventure.node.acf._dow_image.localFile.childImageSharp
                      .fluid
                  }
                />
                <div className="adventure-title">
                  {flyIcon && <div className="adventure-icon">{flyIcon}</div>}
                  <h2>{adventure.node.acf._dow_title}</h2>
                  <div
                    className="adventure-excerpt"
                    dangerouslySetInnerHTML={{
                      __html: adventure.node.acf._dow_excerpt,
                    }}
                  />
                </div>
                <div className="adventure-image-overlay" />
              </Link>
            </AdventureCard>
          )
        })}
      </div>
    </AdventureRowSection>
  )
}

export default AdventureRow
