import React from "react"
import styled from "styled-components"

import { colors, buttonOne } from "../../Utilities"

const SuccessDisplayStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.colorSecondary};
  z-index: 999999999;

  .submit-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .submit-content {
    align-self: center;
    max-width: 50rem;
    padding: 3rem;
    background: #fff;
    border-radius: 0.5rem;
    text-align: center;

    button {
      ${buttonOne};
    }
  }
`

const clearTheForm = stateSetter => {
  stateSetter(prevState => {
    return {
      ...prevState,
      fullName: "",
      yourEmail: "",
      phoneNumber: "",
      message: "",
      submitting: false,
      errorWarnDisplay: false,
      success: false,
      errors: [],
    }
  })
}

const SuccessDisplay = ({ stateSetter }) => {
  return (
    <SuccessDisplayStyled>
      <div className="submit-wrapper">
        <div className="submit-content">
          <p>Your form has successfully been submitted!</p>
          <p>Thank you and we will be in touch very soon!</p>
          <button
            onClick={() => {
              clearTheForm(stateSetter)
            }}
          >
            Done
          </button>
        </div>
      </div>
    </SuccessDisplayStyled>
  )
}

export default SuccessDisplay
