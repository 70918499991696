import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/HeroImage"
import BasicContent from "../components/BasicContent"
import AdventureColumn from "../components/AdventureColumn"
import HeroImageForm from "../components/HeroImageForm"
import TestimonialsSlider from "../components/TestimonialsSlider"

const Adventures = props => {
  const metaTitle = props.data.page.acf._dow_meta_title
  const metaDescription = props.data.page.acf._dow_meta_description
  const metaImage = props.data.page.acf._dow_meta_image.localFile.publicURL

  const heroFluid =
    props.data.page.acf._dow_hero_image.localFile.childImageSharp.fluid
  const displayLogo = props.data.page.acf._dow_hero_main_logo

  const basicContentData = {}
  basicContentData.titleTop = props.data.page.acf._dow_bc_title_top
  basicContentData.titleBot = props.data.page.acf._dow_bc_title_bot
  basicContentData.mainContent = props.data.page.acf._dow_bc_content
  basicContentData.flyPicker = props.data.page.acf._dow_bc_fly_picker

  const hfiData = {}
  hfiData.titleTop = props.data.page.acf._dow_hif_title_top
  hfiData.titleBot = props.data.page.acf._dow_hif_title_bot
  hfiData.mainContent = props.data.page.acf._dow_hif_content
  hfiData.titleFluid =
    props.data.page.acf._dow_hif_image.localFile.childImageSharp.fluid

  return (
    <Layout location={props.location.pathname}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        metaImg={metaImage}
        location={props.location.pathname}
      />
      <HeroImage imgFluid={heroFluid} displayLogo={displayLogo} />
      <BasicContent data={basicContentData} location="adventures" />
      <AdventureColumn />
      <HeroImageForm data={hfiData} />
      <TestimonialsSlider />
    </Layout>
  )
}

export const query = graphql`
  query adventuresPage($id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _dow_meta_title
        _dow_meta_description
        _dow_meta_image {
          localFile {
            publicURL
          }
        }

        _dow_hero_main_logo
        _dow_hero_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        _dow_bc_title_top
        _dow_bc_title_bot
        _dow_bc_content
        _dow_bc_fly_picker

        _dow_hif_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        _dow_hif_title_top
        _dow_hif_title_bot
        _dow_hif_content
      }
    }
  }
`

export default Adventures
