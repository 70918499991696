import React, { Component } from "react"
import BgImg from "gatsby-background-image"
import styled from "styled-components"

import {
  medWrapper,
  colors,
  headlineOne,
  headlineThree,
  bodyCopy,
} from "../Utilities"
import FormChoose from "./FormChoose"

const HeroImageFormSection = styled.section`
  position: relative;
  width: 100%;

  .form-hero-image {
    width: 100%;
    height: 40rem;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 768px) {
      height: 60rem;
    }

    @media (min-width: 1025px) {
      height: 75rem;
    }
  }

  .hif-wrapper {
    ${medWrapper};
  }

  .hif-fields {
    position: relative;
    width: 100%;
    padding: 2rem;
    background: ${colors.colorTertiary};
    z-index: 500;

    @media (min-width: 768px) {
      width: calc(40% - 2rem);
      margin: 1rem;
      padding: 3rem;
    }

    @media (min-width: 1025px) {
      width: calc(33.3333% - 2rem);
      margin: -15rem 1rem 1rem;
      padding: 5rem;
    }
  }

  .hif-content {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(60% - 6rem);
      margin: 0 3rem;
    }

    @media (min-width: 1025px) {
      width: calc(66.6666% - 10rem);
      margin: 0 5rem;
    }

    h2 {
      span {
        display: block;
        text-transform: uppercase;
      }

      span:first-of-type {
        ${headlineThree};
        color: ${colors.colorAccent};
      }

      span:last-of-type {
        ${headlineOne};
        color: ${colors.colorAccent};
      }
    }

    p {
      ${bodyCopy};
    }
  }
`

class HeroImageForm extends Component {
  render() {
    return (
      <HeroImageFormSection>
        <BgImg
          className="form-hero-image"
          Tag="div"
          fluid={this.props.data.titleFluid}
        />
        <div className="hif-wrapper">
          <div className="hif-fields">
            <FormChoose />
          </div>
          <div className="hif-content">
            <h2>
              <span>{this.props.data.titleTop}</span>
              <span>{this.props.data.titleBot}</span>
            </h2>
            <div
              dangerouslySetInnerHTML={{ __html: this.props.data.mainContent }}
            />
          </div>
        </div>
      </HeroImageFormSection>
    )
  }
}

export default HeroImageForm
